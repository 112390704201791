import Vue from 'vue'
import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Page, PageKeys, StaticPagesParams } from './interfaces'
import { FormError, ResponseData } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'pages',
  stateFactory: true,
  namespaced: true
})
export default class PagesModule extends BaseModule<Page, 'id', PageKeys, 'title'> {
  protected listFilters: Partial<Pick<Page, 'title'>> = {
    title: undefined
  }

  protected config: ModuleConfig<Page, 'id'> = {
    apiUrl: '/shop/pages',
    identifier: 'id'
  }

  protected itemData: Page = {
    parentId: null,
    siteId: [],
    code: '',
    title: '',
    fileId: null,
    fileName: '',
    text: '',
    hasChildren: false,
    hasNotChildren: true,
    active: false,
    roles: [],
    fields: {},
    params: {
      isPage: true
    },
    templateId: null,
    page: 0,
    masterId: null
  }

  @Mutation
  resetItem () {
    this.itemData = {
      parentId: null,
      siteId: [],
      code: '',
      title: '',
      fileId: null,
      fileName: '',
      text: '',
      hasChildren: false,
      hasNotChildren: true,
      active: false,
      roles: [],
      fields: {},
      params: {
        isPage: true
      },
      templateId: null,
      page: 0,
      masterId: null
    }
  }

  /**
   * * Удаление свойства сущности по ключам
   * @param keys
   * @returns
   */
  @Mutation
  removeItemPropByKeys (keys: (PageKeys | number)[]) {
    // * Прерывание операции, при отсутствии ключей
    if (!keys || !keys.length) { return }
    // * Создание инстанса сущности
    // @ts-ignore
    let stateInstance = this.itemData
    // * Переменная родительского инстанса
    let parentStateInstance
    // * Сохранение индекса удаляемого свойства
    const removePropIndex = (keys.length - 1)
    for (let i = 0; i < removePropIndex; i++) {
      // * Прерывание операции при отсутствии свойства
      // @ts-ignore
      if (!stateInstance[keys[i]]) { return }
      // * Триггер предпоследней итерации
      if (i === removePropIndex - 1) {
        // * Сохранение родительского инстанса
        parentStateInstance = stateInstance
      }
      // * Замена текущего инстанса, инстансом вложенного свойства
      // @ts-ignore
      stateInstance = stateInstance[keys[i]]
    }
    // * Проверка ключа свойства
    if (typeof keys[removePropIndex] === 'number') {
      // * Если тип число удаление элемента массива
      // @ts-ignore
      (stateInstance as Array<any>).splice(keys[removePropIndex], 1)
    } else {
      // * Если тип строка удаление свойства объекта
      // @ts-ignore
      delete stateInstance[removePropIndex]
    }
    // @ts-ignore
    Vue.set(parentStateInstance, keys[removePropIndex - 1], stateInstance)
  }

  @Action({
    rawError: true
  })
  async getList (pageParams: StaticPagesParams) {
    try {
      const { data } = await $axios.get<ResponseData<Page>>(
        `${this.config.apiUrl}`,
        {
          params: {
            ...pageParams,
            ...(pageParams?.useFilters ? this.listFilters : {}),
            parentId: pageParams?.parentId !== null ? pageParams?.parentId : 'null'
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Привязка ролей к дочерним страницам
   * @param api алиас применяемого свойства
   * @param id id родительской страницы
   */
  @Action({
    rawError: true
  })
  async updateChildren ({ api, id } : { api : 'sites' | 'roles', id: number }) {
    try {
      await $axios.post(`/shop/pages/${id}/update-children-${api}`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
